//
// general style for images
//
/*! purgecss start ignore */
figure.ce-ratio-box__content {
    margin: 0;
  }
  
  // default ratio box - as we use mobile first, the default shall be square
  .ce-ratio-box {
  
    position: relative;
  
    &.ce-fullscreen {
      position: absolute;
    }
  
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%;
    }
  
    & > .ce-ratio-box__content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
    }
  }
  
  // settings for the 4:3 variant
  .ce-ratio-box--4-3 {
  
    &:before {
      padding-top: 75%;
    }
  }

  .ce-ratio-box--3-4 {

    &:before {
      padding-top: 100%;
    }
  }
  
  // settings for the 16:9 variant
  .ce-ratio-box--16-9 {
  
    &:before {
      padding-top: 56.25%;
    }
  }
  
  // settings for the (very) wide variant
  .ce-ratio-box--wide {
  
    &:before {
      padding-top: 75%;

      @include breakpoint(medium) {
        padding-top: 45%;
      }
  
      @include breakpoint(large) {
        padding-top: 30%;
      }
    }
  }
  
  // adding option to object-fit anything (eg. <img>) inside ratio box
  // TODO: check if this rather might be a global helper class
  .object-fit {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  
    object-fit: cover; // shall be default, other options could be added, if needed via modifier class

    &.object-fit--contain {
      object-fit: contain;
    }
  
    &.object-fit--fill {
      object-fit: fill;
    }
  
    &.object-fit--none {
      object-fit: none;
    }
  
    //special fits size
    $sizes: 95, 90, 85, 80, 75, 70, 65, 55, 50;
    @each $size in $sizes {
      &.object-fit--#{$size} {
        top: 50%;
        left: 50%;
        width: #{$size};
        height: #{$size};
        object-fit: contain;
        transform: translate(-50%, -50%);
      }
    }
  
    // img needs 100% width otherwise it won't fill the container if the image file is smaller than the container
    img {
      width: 100%;
    }
  }
  /*! purgecss end ignore */