/*! purgecss start ignore */
.notification {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.notification--content {
  display: flex;
}

.notification--icon {
  padding-right: .75rem;
}

.notification--button { 
  padding: .25rem .5rem;
  margin: 1rem 0 0 2rem;
  color: #fff;
  border: 2px solid white;

  &:hover {
    color: #fff;
  }
}
/*! purgecss end ignore */