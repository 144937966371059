body {
	&.no-scroll {
		overflow: hidden;
		height: 100%;
	}
}

.page-content {
	margin-top: 8vh;
	margin-bottom: 0;
	min-height: calc( 70vh );

	@include breakpoint(large) {
		min-height: calc( 100vh - 8vh );
	}
}

.ce-content {
	padding: 1rem;
	@include breakpoint(large) {
		padding: 2rem;
	}
}

.page-content__content-elements {

	& > .grid-margin-x {
		margin: 0;
	}
}

.ce-elements {

	@include breakpoint(large) {
		// arbeitgeber profil, too much padding top
		&.large-offset-2 {
			padding-top: 0;
		}
	}

	& > .ce {
		padding: 0 $general-padding;

		@include breakpoint(large) {
			// arbeitgeber profil on big desktop( not overflow )
			&.ce--container_66_33,
			&.ce--mask_employerjobformatrequest {
				padding: 0 ($general-padding - 3vw);
			}
		}
		
		@include breakpoint(xxxlarge) {
			padding: 0;
		}
	}
}

.max-content {
	@include breakpoint(xxxlarge) {
		max-width: 100%;
		width: 100rem;
		margin-left: auto;
		margin-right: auto;
		
		.ce--frame-default {
			max-width: 100%;
		}
	}
}

.ce {
	position: relative;
}

/**
 * CE - space before classes
 */

/*! purgecss start ignore */
.ce--space-before-none {
	margin-top: 0!important;
}

.ce--space-before-super-small {
	margin-top: 1vh!important;
}

.ce--space-before-extra-small {
	margin-top: 2.5vh!important;
}

.ce--space-before-default,
.ce--space-before-small {
	margin-top: 5vh!important;;
}

.ce--space-before-medium {
	margin-top: 7.5vh!important;;
}

.ce--space-before-large {
	margin-top: 10vh;
}

.ce--space-before-extra-large {
	margin-top: 15vh;
}

/**
 * CE - space after classes
 */

.ce--space-after-none {
	margin-bottom: 0;
}

.ce--space-after-extra-small {
	margin-bottom: 2.5vh;
}

.ce--space-after-default,
.ce--space-after-small {
	margin-bottom: 5vh;
}

.ce--space-after-medium {
	margin-bottom: 7.5vh;
}

.ce--space-after-large {
	margin-bottom: 10vh;
}

.ce--space-after-extra-large {
	margin-bottom: 15vh;
}

.ce--divider-before {
	position: relative;
	padding-top: 2rem;
	clear: both;

	&:before {
		position: absolute;
		top: 0;
		content: '';
		width: 10rem;
		border-top: 1px solid $medium-gray;
	}
}
/*! purgecss end ignore */

a {
	color: $color-red;
	text-decoration: none;
	transition: $transition;
	&:hover {
		text-decoration: underline;
	}
}

.raw-text {
	white-space: pre-line;
}