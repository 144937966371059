.contenttable {
    font-size: 1rem;
    width: 100%;

    tbody td,
    thead td {
        /*! purgecss ignore current */
        padding: .5rem 1rem;
        .ce--layout-bg-color-dark &,
        .ce--layout-bg-color-gray &,
        .ce--layout-bg-highlight-color & {
            padding: 0;
        }
    }

    thead tr {
        /*! purgecss ignore current */
        text-transform: uppercase;
        font-weight: 700;
    }

    tbody tr {
        &:nth-child(2n+1) {
            background-color: #f0f3f6;
            .ce--layout-bg-color-dark &,
            .ce--layout-bg-color-gray &,
            .ce--layout-bg-highlight-color & {
                background-color: transparent;
            }
        }
    }
}