//
// general style for images
//

figure.ratio-box__content {
    margin: 0;
  }
  
  // default ratio box - as we use mobile first, the default shall be square
  .ratio-box {
  
    position: relative;
  
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%;
    }
  
    & > .ratio-box__content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  
  // settings for the 4:3 variant
  .ratio-box--4-3 {
  
    &:before {
      padding-top: 75%;
    }
  }
  
  // settings for the 16:9 variant
  .ratio-box--16-9 {
  
    &:before {
      padding-top: 56.25%;
    }
  }
  
  // settings for the (very) wide variant
  .ratio-box--wide {
  
    &:before {
      padding-top: 45%;
  
      @include breakpoint(large) {
        padding-top: 30%;
      }
    }
  }
  
  // adding option to object-fit anything (eg. <img>) inside ratio box
  // TODO: check if this rather might be a global helper class
  .object-fit {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  
    object-fit: cover; // shall be default, other options could be added, if needed via modifier class
  
    // img needs 100% width otherwise it won't fill the container if the image file is smaller than the container
    img {
      width: 100%;
    }
    &.object-fit--contain {
      object-fit: contain; 
    }
  }
  