.jobdetails__col_left {
    order: 2;
    @include breakpoint(medium) {
        order: 1;
    }
}

.jobdetails__col_left_sticky_container {
    @include breakpoint(medium) {
        position: sticky;
        top: 6vh;
        z-index: 100;
        padding: 1rem 1rem 0 0;
    }

    @include breakpoint(large) {
        padding: 1rem 2rem 0 0;
    }
}

.jobdetails__text {
    order: 1;

    @include breakpoint(medium) {
        order: 2;
    }

    h2, .h2 {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        font-size: 1.75rem;
    }

    h3, .h3 {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
    }

    .cropApiImage {
        margin: 0 0 2rem 0
    }

    // Hide inline styles from rte textarea
    *[style] {
        color: inherit !important;
    }
}

.jobdetails__more-jobs {
    order: 3;
    h2, .h2 {
        margin-top: 4rem;
        margin-bottom: 2rem;
        font-size: 1.75rem;
    }

    h3, .h3 {
        margin-top: 4rem;
        margin-bottom: 2rem;
        font-size: 1.5rem;
    }
}