.container-column {
	> .ce {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.ce--layout-top-bottom-border-color-gray ,
		&.ce--layout-top-border-color-gray ,
		&.ce--layout-bottom-border-color-gray {
			&:only-child {
				height: calc(100% - #{$layout-border-width});
			}
		}
	}
	
	// special style for a container_50 in other container_50

	.ce--container_50_50 .ce--container_50_50 {
		.container-column {
			margin-bottom: 0;
			@include breakpoint(xxlarge down) {
				flex: 0 0 100%;
				width: 100%;
				margin-bottom: 1rem;
			}
		}
	}
}

.ce--layout-header-left-dark {
	padding: 10vh 5vw!important;
	color: $white;
	background-color: $bg-black;

	.ce__header_link > .button {
		@extend .button--hollow;
	}

	.ce--job-teaser {
		padding: 0;
	}

	.teaser {
		

		&:hover {
			border-color: #fff;
		}
	}
	

	@include breakpoint( large ) {

		> .ce__content-wrapper > .ce__content-wrapper-column {

			> .ce__header {
				float: left;
				position: sticky;
				top: 10vh;
				width: 20%;
			}

			> .ce__container-content {
				width: 80%;
				margin: 0 0 0 auto;
			}
		}

		.ce--job-teaser {
			max-width: 100%;
			margin: 0;
		}
	}

	&.ce--layout-header-left-dark--without-headline {
		> .ce__content-wrapper > .ce__content-wrapper-column > .ce__container-content {
			width: 80%;
			margin: 0 auto;
		}
	}
}

// LR-313 ⁃ Artikellisten-Teaser: Artikel bündig ausrichten
.ce--container_33_33_33,
.ce--container_50_50,
.ce--container_100 {
	&.ce--layout-default {
		@include breakpoint(medium) {
			padding: 0 2rem;
			.ce--container_66_33 &,
			.ce--layout-default {
				padding: 0;
			}
		}
	}
}

// headlines of arbeitgeber profil and unis profil
.ce--container_66_33 {
	.ce_onpage_listener {
		& > .ce__header {
			@include breakpoint(large) {
				h1, h2 {
					width: calc(75% - 1.25rem);
				}
			}
		}
	}
}