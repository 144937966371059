.feature-filter {
  border: 1px solid #000000;
}

//TODO remove after correct embed of baseweb
ul[data-baseweb="accordion"] {
  margin-left: 0;
}

.feature-tab-wrapper {
  margin: 4rem 0 0 0;
  padding: 0 1rem;

  @include breakpoint(large) {
    padding: 0;
  }
}

.feature-teaser {
  position: relative;
  padding: 2rem;
  margin: 6rem 0;
  background-color: #e6e6e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

  @media print, screen and (min-width: 40em) {
    margin: 5rem 0;
  }

  &.feature-teaser--error {
    background-color: #df1051;
    color: #fff;

    .button--bg-red {
      border: 2px solid #fff;
    }
  }

  &.feature-teaser--success {
    background: linear-gradient(#38393a, #484a4b);
    color: #fff;
  }

  &:nth-child(1) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .teaser--next,
  .teaser--back {
    width: 15rem;
    border-radius: 2rem;
    border: 10px solid #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    color: #fff;
    text-align: center;
    cursor: pointer;
    @media print, screen and (min-width: 40em) {
      padding: 2rem 1rem;
    }
  }

  .teaser--next {
    background-color: #df1051;
    margin-bottom: -7rem;
  }

  .teaser--back {
    margin-top: -7rem;
    //background-color: #38393a;
    background-color: #df1051;
  }
}

.feature-teaser__content {
  width: 100%;
  @media print,
  screen and (min-width: 40em) {
    width: 85vw;
  }

  @media print,
  screen and (min-width: 64em) {
    width: 40vw;
  }

  .section {
    margin: 2rem 0;
  }

  .section--border {
    border: 4px solid #fff;
    border-radius: 1rem;
    padding: 1rem;
  }

  .section__icon {
    margin-right: 1rem;
  }

  .section__content {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  a {
    text-decoration: underline;
    color: inherit;
  }
}

.feature-teaser__headline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around
}

.feature-teaser__icon {
  //border-bottom-left-radius: 2rem;
  //border-bottom-right-radius: 2rem;
  border-radius: 2rem;
  padding: 1rem;
  width: 6rem;
  height: 4.5rem;
  background-color: #fff;
  color: #df1051;
  justify-self: flex-start;
  box-shadow: 0 5px 20px 5px rgba(255, 255, 255, 0.15);
  @media print,
  screen and (min-width: 40em) {
    width: 6rem;
    height: 6rem;
  }
  @media print,
  screen and (min-width: 64em) {
    width: 8rem;
    height: 8rem;
  }
}

.fx-icon-infinite-move-bottom {
  will-change: transform;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-animation: move-to-bottom 1s cubic-bezier(0.65, 0.05, 0.36, 1) 0s infinite alternate both;
  animation: move-to-bottom 1s cubic-bezier(0.65, 0.05, 0.36, 1) 0s infinite alternate both;
}

@-webkit-keyframes move-to-bottom {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 10px, 0);
  }
}

@-moz-keyframes move-to-bottom {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 10px, 0);
  }
}

@-o-keyframes move-to-bottom {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 10px, 0);
  }
}

@keyframes move-to-bottom {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 10px, 0);
  }
}

// delete margin between feature teaser und footer
.feature-teaser.last {
  margin-bottom: 0;
}