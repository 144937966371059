.bar {

  .ce--layout-bg-highlight-color & {
    background: repeating-linear-gradient(
                    to right,
                    var(--highlight-invert-bw-color),
                    var(--highlight-invert-bw-color) 3px,
                    transparent 3px,
                    transparent 9px
    );
  }

  .ce--layout-bg-color-dark & {
    background: repeating-linear-gradient(
                    to right,
                    var(--highlight-invert-for-dark-bg-color),
                    var(--highlight-invert-for-dark-bg-color) 3px,
                    transparent 3px,
                    transparent 9px
    );
  }

  .ce--layout-bg-color-gray & {
    background: repeating-linear-gradient(
                    to right,
                    var(--highlight-invert-for-bright-bg-color),
                    var(--highlight-invert-for-bright-bg-color) 3px,
                    transparent 3px,
                    transparent 9px
    );
  }
}

.percent {

  .ce--layout-bg-highlight-color & {
    color: var(--highlight-invert-bw-color);
  }

  .ce--layout-bg-color-dark & {
    color: var(--highlight-invert-for-dark-bg-color);
  }

  .ce--layout-bg-color-gray & {
    color: var(--highlight-invert-for-bright-bg-color)!important;
  }
}

.barLast {

  .ce--layout-bg-highlight-color & {
    background-color: var(--highlight-invert-bw-color);
  }

  .ce--layout-bg-color-dark & {
    background-color: var(--highlight-invert-for-dark-bg-color);
  }

  .ce--layout-bg-color-gray & {
    background-color: var(--highlight-invert-for-bright-bg-color)!important;
  }
}