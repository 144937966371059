.previewInfo {
  position: fixed;
  z-index: 1001;
  left: 1rem;
  bottom: 1rem;
  background-color: #df1051;
  padding: 1rem;
  color: #fff;
  border: solid 1px #fff;
  width: 20rem;
}

.previewTitle {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
}

.previewDescription {
  display: block;
  font-size: .875rem;
}

.clearLink {
  position: absolute;
  bottom: .5rem;
  right: .5rem;
  color: #fff;
  font-size: .875rem;
}

.infoToogleTitle {
  font-size: .875rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: start;

  .icon {
    top: 1rem
  }
}

.infoToogleText {
  font-size: .875rem;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;

  p {
    font-size: .875rem;
    margin-top: .5rem;
  }

  &.open {
    max-height: 200px;
    height: auto;
    transition: max-height 0.25s ease-in;
  }
}