.ad {
  width: 100%;
  text-align: center;
  margin-top: 1rem;

  .ad-element-container,
  .ad-element {
    position: relative;
    display: inline-block;
  }

  .adglare-label {
    position: absolute;
    top: -.1rem;
    right: 0;
    font-size: .75rem;
    color: #595959;
  }
}

.ad iframe {
  margin: 1rem 0!important;
  
  //@media print,
  //screen and (min-width: 40em) {
  //  margin: 1rem 5vw!important;;
  //}
  //@media print,
  //screen and (min-width: 64em) {
  //  margin: 1rem!important;;
  //}

  + .page_header_image_wrapper,
  + .page_header_on_page_nav {
    margin-top: 0!important;;
  }
}