.fixed-page-bottom-actions {
  position: fixed;
  bottom: 4.5rem;
  right: 1.75rem;
  width: 50px;
  display: flex;
  flex-direction: column;
  z-index: 994;

  > .action-item {
    margin-bottom: 1rem;
  }
  
  &.to-top {
    bottom: 8.5rem;
    z-index: 993;
  }
}

.action-item {
  position: relative;
  color: $white;
  border-radius: 10px;
  cursor: pointer;
  z-index: 994;

  &:hover {
    &+.action-item__hover {
      opacity: 1;
      transform: translate(0px, 0);
      transition: transform $global-transition-style 0s, opacity $global-transition-style .15s;
    }
  }

  &.action-item--scroll-top {
    transition: transform $global-transition-style 0s, opacity $global-transition-style .15s;
  }
}

.action-item--hide {
  transform: translateX(5rem);
}

.action-item__icon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 60px;
  background-color: $color-red;
  z-index: 10;

  svg {
    margin-left: -5px;
    .action-item--contact & {
      margin-left: 0;
    }
  }
}

.action-item__hover {
  position: fixed;
  bottom: 5.5rem;
  right: 1.5rem;
  opacity: 0;
  transform: translate(200px, 0);
  transform-style: preserve-3d;
  z-index: 990;
  transition: transform $global-transition-style .15s, opacity $global-transition-style 0s;

  .to-top & {
    bottom: 8.5rem;
  }
}

.action-item__force_hover {

  & + .action-item__hover {
    opacity: 1;
    transform: translate(0px, 0);
  }
}

.action-item__icon--action {
  svg {
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0;
  }
}

.action-item__icon--close {
  svg {
    margin-left: 0;
  }
}

.action-item__text {
  margin-right: 1.5625rem;
  padding: 1rem 2.25rem 1rem 1.125rem;
  border: 2px solid $color-red;
  background-color: $white;
  color: $color-red;
  font-size: .75rem;
  font-weight: $global-weight-bold;
  border-radius: 50px 0 0 50px;
  cursor: pointer;
  margin-bottom: 0;

  @include breakpoint(medium) {
    padding: .8rem 2.35rem .8rem 1.25rem;
    font-size: 1rem;
  }
}