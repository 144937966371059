.badge {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 10rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem;
    border-radius: 2rem;
}

.badge--success {
    background-color: #47db76;
}

.badge--error {
    background-color: #df1051;
}

.badge--inactive {
    background-color: #df1051;
}

.badge--inline {
    display: inline-block;
    margin: 0 1rem;
}

.badge--inline-block {
    display: inline-block;
    margin: 0 1rem;
    padding: 0 0.5rem;
}

.badge--small {
    //width: 1rem;
    //height: 1rem;

    .badge__icon {
        width: .9rem;
        height: .9rem;
    }
    .badge__text {
        font-size: .6rem;
        max-width: 0;
        overflow: hidden;
        transition: max-width 1s ease-in-out;
    }

    &:hover {
        .badge__text {
            max-width: 500px;
        }
    }
}

.badge--interactive {
    vertical-align: sub;
    min-width: 15px;
    height: 15px;
    float: left;
    display: flex;
    transition: transform .5s ease-in-out;
    .badge__text {
        max-width: 0;
        overflow: hidden;
        transition: max-width .5s ease-in-out;
    }

    &:hover {
        transform: scale(1);
        .badge__text {
            //visibility: visible;
            transition: max-width .5s ease-in-out;
            max-width: 500px;
        }
    }
}

.badge--icon {
    margin: 0;
    padding: 0.75rem;
    border-radius: 50%;
    color: $white;
}

.badge__icon,
.badge__text {
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: uppercase;
    color: $white;
}