.sidebar-arbeitgeber {
    position: fixed;
    top: 200px;
    right: -100%;
    background-color: $white;
    z-index: 10;
    transition: $transition;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

    &.expand {
        right: -30%;
        @include breakpoint(medium) {
            right: -65%;
        }
    }

    @include breakpoint(large) {
        position: static;
        box-shadow: none;
    }
}

button.sidebar__button {
    position: absolute;
    top: 0;
    left: -40px;
    background-color: $white;
    padding: .5rem 0;
    border-radius: 20px 0 0 20px;
    border: 1px solid $medium-gray;
    width: 40px;
    border-right: 0;
    
    & > svg {
        transform: rotate(90deg);
        transition: $transition;
        .expand & {
            transform: rotate(270deg);
        }
    }

    @include breakpoint(large) {
        display: none;
    }
}