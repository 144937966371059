$circle-size: 15px;

.switch {
  display: inline-block;
  width: 40px;
  height: 21px;
}

.switch__text {
    line-height: 1; 
}

.switch__input {
  opacity: 0;
  width: 0;
  height: 0;

  &:checked {
    & + .switch__slider {
      background-color: $color-dark-red;
      &:before {
        transform: translateX($circle-size);
      }
    }
  }
  &:focus {
    & + .switch__slider {
      box-shadow: 0 0 1px $color-dark-red;
    }
  }
}

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $medium-gray;
  border-radius: 34px;
  transition: $transition;
}

.switch__slider:before {
  position: absolute;
  content: "";
  height: $circle-size;
  width: $circle-size;
  left: 5px;
  bottom: 3px;
  background-color: $white;
  border-radius: 50%;
  transition: $transition;
}
