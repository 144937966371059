//
// style for global buttons
//

.button {
  position: relative;
  display: inline-flex;
  justify-content: start;
  align-items: center;
  width: auto;
  max-width: 16rem;
  padding: 0.5rem 1rem;
  box-sizing: content-box;
  border-style: solid;
  border-width: 3px;
  border-color: transparent;
  font-family: $body-font-family;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none!important;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: $transition;

  &:hover {
    text-decoration: none;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }
}

.button--auto-width {
  max-width: fit-content;
}

.button--small {
  padding: 0.5rem 1rem;
  border-width: 1px;
}

.button--font-normal {
  font-style: normal;
}

.button--no-padding {
  display: inline-block;
  padding-left: 0;

  svg {
    height: 0.8rem;
  }
}

.button--size-small {
  font-size: 0.825rem;
}

.button--white {
  color: $white;
  border-color: $white;
}

.button--white-border-grey {
  color: $black;
  border: 2px solid rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #f0f3f6;
  }
}

.button--rect,
.button--rect:hover {
  width: 3rem;
  height: 3rem;
  line-height: 1.125rem;
  border-radius: 2px;
}

.button--border-white {
  color: $white;
  border-color: $white;
  &:hover {
    color: $white;
    border-color: $color-red;
    background-color: $color-red;
  }
}

.button--red {
  border-color: $color-red;
  &:focus {
    color: $color-red;
  }
  &:hover {
    color: $white;
    background-color: $color-red;
  }

  &:disabled,
  &.disabled {
    color: $dark-gray;
    border-color: $dark-gray;

    &:hover {
      color: $white;
      background-color: $dark-gray;
    }
  }
}

.button--red-arrow {
  svg {
    color: $color-red;
  }
}

.button--bg-red {
  color: $white;
  background-color: $color-red;
  &:hover {
    color: $white;
    background-color: $color-violet;
  }

  &:disabled,
  &.disabled {
    background-color: $medium-gray;
    cursor: not-allowed;

    &:hover {
      color: $white;
    }
  }
}

.button__hover-to-bg-blue {
  &:hover {
    color: $white !important;
    border-color: $color-violet !important;
    background-color: $color-violet !important;
  }
}

.button--bg-white {
  color: $color-red!important;
  background-color: $white;
  border-color: $color-red;
  &:hover {
    color: $white!important;
    background-color: $color-red;
  }

  &:disabled,
  &.disabled {
    background-color: $medium-gray;
    cursor: not-allowed;

    &:hover {
      color: $white;
    }
  }
}

.button--grey {
  color: $white;
  background-color: $dark-gray;

  &:hover {
    color: $dark-gray;
    background-color: $white;
    border-color: $dark-gray;
  }
}

.button--black-hollow {
  color: $black;
  border-color: $black;
  &:hover {
    color: $white;
    background-color: $black;
  }
}

.button--black {
  color: $white;
  background-color: $black;
  border-color: $black;
  &:hover {
    color: $white;
    background-color: $black;
  }
}

.button--mobile-small {
  @media print, screen and (max-width: 64em) {
    padding: 0.5rem 0.5rem;
    font-size: 0.75rem;
  }
}

.flex--buttons {
  display: flex;
  gap: 1rem;
}

.button-group {
  .button {
    margin: 0 1rem 1rem 0;
    @media print, screen and (min-width: 64em) {
      margin: 0 1rem 0 0;
    }
  }

  &.content-center {
    .button {
      margin: 0 0 1rem 0;
      @media print, screen and (min-width: 64em) {
        margin: 0 1rem 0 0;
      }
    }
  }
}

.button--center {
  gap: 0.5rem;
  align-items: center;
}

.button-group--center {
  justify-content: center;
}

.button-group--right {
  justify-content: flex-end;
}

// style for button in profil page
.btn--options {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 30rem;
  padding: 1rem 2rem 1rem 4rem;
  margin: 0;
  color: $black;
  border: 1px solid $black;
  background-color: $white;
  text-transform: uppercase;
  font-weight: 600;
  transition: $transition;
  cursor: pointer;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
    background-color: $black;
    width: 3rem;
    height: 100%;
    transition: $transition;
  }

  .message {
    font-size: 0.75rem;
    text-transform: none;
    line-height: 1.1;
  }

  &:hover {
    color: $white;
    background-color: $black;
    text-decoration: none;
    .logo {
      color: $black;
      background-color: $white;
    }
  }

  &.isDisabled {
    &:hover {
      color: $black;
      border: 1px solid $black;
      background-color: $white;
      .logo {
        color: $white;
        background-color: $black;
      }
    }
  }
}

.btn--profil {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 1rem 0 1rem;
  max-width: 16.875rem;
  outline: 0;
  padding: 0.5rem 2.5rem;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $black;
  border: 1px solid #5f6368;
  background-color: $white;
  border-radius: 100px;
  letter-spacing: 0.25px;
  transition: $transition;
  cursor: pointer;
  &:hover {
    color: $white;
    background-color: $black;
    border: 1px solid #5f6368;
    text-decoration: none;
  }
}

// general buttons
.button {
  &.button--primary-hover {
    background-color: $color-violet;
    border-color: $color-violet;
    color: $white;
  }

  &.button--primary {
    background-color: $color-red;
    border-color: $color-red;
    color: $white;
    &:hover {
      @extend .button--primary-hover;
    }
  }

  &.button--secondary-hover {
    background-color: $color-violet;
    border-color: $color-violet;
    color: $white;
  }

  &.button--secondary {
    background-color: $white;
    border-color: $black;
    color: $black;
    &:hover {
      @extend .button--secondary-hover;
    }
  }

  &.button--third {
    border-color: transparent;
    background-color: transparent;
    color: $black;
    text-decoration: underline;
    &:hover {
      border-color: transparent;
      background-color: transparent;
      color: $black;
      text-decoration: none;
    }
  }

  &.button--hollow {
    background-color: transparent;
    border-color: $white;
    color: $white;
    &:hover {
      background-color: $white;
      border-color: $black;
      color: $black;
    }
  }

  &.button--hollow-light {
    background-color: transparent;
    border-color: $black;
    color: $black;

    &:hover {
      background-color: $color-violet;
      border-color: $color-violet;
      color: $white;
    }
  }

  &.button--primary,
  &.button--secondary,
  &.button--primary-hover,
  &.button--secondary-hover,
  &.button--third,
  &.button--hollow {
    padding: 0.75rem 1rem;

    &.disabled {
      border-color: $light-gray;
      color: $black;
      background-color: $light-gray;
      cursor: not-allowed;

      &:hover {
        border-color: $light-gray;
        color: $black;
        background-color: $light-gray;
      }
    }

    svg {
      width: 1rem;
      height: 1rem;
      margin: 0;
      //// spinner
      //path:nth-child(2) {
      //  fill: $black;
      //  opacity: 0.15;
      //}
      //path:last-child {
      //  fill: $color-violet;
      //}
    }
    &:hover {
      text-decoration: none;
      //svg {
        // spinner
        //path:nth-child(2) {
        //  fill: $white;
        //  opacity: 1;
        //}
      //}
    }
  }

  &.button--fadeIn {
    svg {
      width: 0rem;
      margin: 0;
      transition: $transition;
    }
  
    &:hover {
      width: auto;
      svg {
        width: 3.25rem;
        margin: 0 0 0 1rem;
      }
    }
  }
}
