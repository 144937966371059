//.pagination ul {
//	display: inline-block;
//	padding-left: 15px;
//	padding-right: 15px;
//}
//
//.pagination li {
//	display: inline-block;
//	margin: .25rem;
//}

.pagination {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 6vh 0 0;
    padding: 0;

    li {

        &.pagination-previous:before,
        &.pagination-next:before {
            display: none;
        }

        
        
        a {
            padding: 0.5rem 1rem;
            margin: 0 .5rem;
            font-size: 1rem;
            text-decoration: none;
        }

        &.disabled {
            a {
                color: $medium-gray;

                &:hover {
                    color: $medium-gray;
                    background-color: transparent;
                }
            }   
        }

        &.current {
            padding: 0;
            background-color: transparent;

            a {
                background-color: $color-red;
                color: $white;
            }
        }
    }
}