.overlay {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 0;
}

.overlay--black {
  background-color: rgba(0, 0, 0, 0.4);
}

.no-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.list-flex {
  display: flex;
}

.list-flex-margin--small > * {
  margin-right: 0.5rem;
}

.list-flex--center {
  align-items: center;
}

.bg-white {
  background-color: $white;
}

.hover-effect-border {
  position: relative;
  text-decoration: none;
  transition: $transition;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    height: 2px;
    background-color: $color-red;
    transition: $transition;
  }

  &:hover {
    color: $color-red;
    text-decoration: none;

    &:before {
      width: 100%;
    }
  }

  .active > &,
  &.active-onpage,
  &.active {
    border-left-color: $color-red;
    color: $color-red;

    &:before {
      width: 100%;
      background-color: $color-red;
    }
  }

  &.hover-effect-border--white {
    color: $white;

    &:before {
      background-color: $color-red;
    }

    &:hover {
      color: $color-red;
    }
  }
}

.hover-effect-img-filter {
  img:not(.top-image) {
    transition: 0.3s all cubic-bezier(0.455, 0.03, 0.515, 0.955);
    filter: brightness(90%) saturate(30%);

    .ce--layout-header-left-dark & {
      filter: grayscale(100%) brightness(70%) hue-rotate(180deg) contrast(120%);
    }
  }

  &:hover {
    img {
      filter: grayscale(20%) brightness(80%) hue-rotate(0deg) contrast(100%);

      .ce--layout-header-left-dark & {
        filter: grayscale(20%) brightness(80%) hue-rotate(0deg) contrast(100%);
      }
    }
  }
}

.hide-visibility {
  visibility: hidden;
}

.animation-dropdown {
  max-height: 0;
  overflow: hidden;
  transition: $transition;
  &.open {
    max-height: 500rem;
  }
}

.blur {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(8px);
  transition: opacity 2s ease-out;
  opacity: 1;

  &.hide {
    opacity: 1;
  }
}

.uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-small {
  font-size: 0.875rem;
}

.text-default {
  font-size: 1rem;
}

.text-medium {
  font-size: 1.25rem;
}

.text-large {
  font-size: 1.5rem;
}

.text-xlarge {
  font-size: 1.75rem;
}

.text-xxlarge {
  font-size: 2.25rem;
}

.weight-3 {
  font-weight: 300;
}

.weight-4 {
  font-weight: 400;
}

.weight-5 {
  font-weight: 500;
}

.weight-6 {
  font-weight: 600;
}

.weight-7 {
  font-weight: 700;
}

.color-gray {
  color: $dark-gray;
}

.color-red {
  color: $color-red;
}

.color-violet {
  color: $color-violet;
}

.color-alert {
  color: get-color(alert);
  // special case for disabled tabheader
  [data-baseweb="tab"]:disabled & {
    color: $dark-gray;
  }
}

.color-warning {
  color: get-color(warning);
}

.color-success {
  color: get-color(success);
  // special case for disabled tabheader
  [data-baseweb="tab"]:disabled & {
    color: $dark-gray;
  }
}

.color-white {
  color: $white;
}

.color-black {
  color: $black;
}

.color-gold {
  color: $color-gold;
}

.bg-black {
  background-color: $black;
}

.bg-white {
  background-color: $white;
}

.bg-red {
  /*! purgecss ignore current */
  background-color: $color-red;
}

.color-sucess {
  color: #47db76;
}

.color-error {
  color: rgb(225, 25, 0);
}

.color-warning {
  color: #df1051;
}

.bg-error {
  background-color: #f0b7b7;
}

.bg-violet {
  background-color: $color-violet;
}

.bg-gray {
  background-color: #f5f5f5;
}

.bg-medium-gray {
  background-color: #e6e6e6;
}

.br-black {
  border-color: $black;
}

.br-white {
  border-color: $white;
}

.br-alert {
  border-color: get-color(alert);
}

.content-center {
  display: flex;
  justify-content: center!important;
  align-items: center!important;
}

.content-column {
  flex-direction: column;
}

.isDisabled {
  position: relative;
  color: $black !important;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

// rotate
.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

// flex
.grid-col {
  flex-direction: column;
}

.grid-items-left,
.grid-items-center,
.grid-items-right,
.align-middle {
  display: flex;
  align-items: center;
}

.grid-items-left {
  justify-content: flex-start;
  text-align: left;
}
.grid-items-right {
  justify-content: flex-end;
  text-align: right;
}

.grid-items-center {
  justify-content: center!important;
  text-align: center;
}

.grid-items-top {
  align-items: flex-start;
}

.grid-space-between {
  display: flex;
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.clickable {
  cursor: pointer;
}

// border-raidus

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.stickyBottomAboveBottomContainer {
  @media print, screen and (max-width: 64em) {
    bottom: 5.5rem!important;
  }
}
// honey pot field hide class
.hp-new-contact-name {
  visibility: hidden;
  z-index: 0;
  pointer-events: none;
  position: absolute;
  left: -1000px;
  top: -1000px;
}