.breadcrumb {
  display: flex;
  width: 100%;
  margin: 0;
  list-style: none;
  padding: 7px 0 7px 5vw;

  @media print,
  screen and (min-width: 64em) {
    height: 2vw;
    align-items: center;
    padding: 7px 0 7px 2vw;
  }

  &:before,
  &:after {
    display: table;
    content: ' ';
  }

  li {
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;

    &:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca;
    }
  }

  a {
    color: rgb(10, 10, 10);
  }
}