.add_to_search-subscriptions {
    @include breakpoint(medium) {
        margin: 0;
    }
}

.add_to_search-subscriptions__button {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.7rem;
    text-align: center;
    line-height: 1.2;
    cursor: pointer;
    transition: $transition;
    width: 4rem;
    &:hover {
        color: $color-red;
    }
}
.search-subscription-list {
    margin: 2rem 0;
}
.search-subscription-item {
    padding: 1.5rem 2rem;
    margin: 2rem 0;
    border-radius: 1.25rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transform: translate(0px, 0px);
    transform-style: preserve-3d;
    box-shadow: 0 10px 8px 0 rgba(136, 145, 155, 40%);
    transition: $transition;

    &:hover {
        box-shadow: none;
        transform: translate(0px, 5px);
        border-color: $black;
    }
}
.search-subscription-item__title {
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
    
    a {
        color: $black;
    }

    &:hover {
        text-decoration: underline;       
    }
}
.search-subscription-item__readableFilter {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0 0;
    span {
        display: inline-block;
        margin-left: 0.5rem;
    }
}
.search-subscription-item__lastSentTime {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0 0;
    font-size: 0.8em;
    span {
        display: inline-block;
        margin-left: 0.5rem;
    }
}

.search-subscription-item__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 0 0;
    @include breakpoint(medium) {
        justify-content: flex-end;
        margin: -1rem 0 0 0;
    }

    .button {
        margin: 0 0 0 2rem;
    }
}