//
// style for subcription 
//

.popUp_contact {
    position: fixed;
    display: none;
    height: 85%;
    width: 90vw;
    right: 5vw;
    bottom: 5vw;
    background-color: $white;
    box-shadow: 0px 10px 10px 5px rgba(85, 48, 48, 0.20);
    border-radius: 10px 10px 20px 10px;
    animation: contact .5s cubic-bezier(.455, .03, .515, .955) 0s 1 alternate;
    z-index: 993;

    &.open {
        display: block;
    }

    @include breakpoint(medium) {
        height: auto;
        width: 290px;
    }

    @include breakpoint(large) {
        right: 2vw;
        bottom: 2vw;
    }
}

@keyframes contact {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

.popUp_contact__scroll {
    height: 100%;
    padding: 2rem 1rem;
    overflow-y: auto;

    @include breakpoint(medium) {
        padding: 2rem;
    }
}