//
// style for subcription 
//

.popUp_subscription {
    position: fixed;
    display: none;
    //height: 85%;
    width: 85vw;
    right: 1.75rem;
    bottom: 6rem;
    background-color: $white;
    box-shadow: 0px 10px 10px 5px rgba(85, 48, 48, 0.20);
    border-radius: 10px 10px 20px 10px;
    animation: newsletter .5s cubic-bezier(.455, .03, .515, .955) 0s 1 alternate;
    z-index: 993;

    &.open {
        display: block;
    }

    @include breakpoint(medium) {
        height: auto;
        width: 50vw;
    }

    @include breakpoint(large) {
        width: 25vw;
        max-width: 400px;
        right: 2vw;
        bottom: 6rem;
    }
}

@keyframes newsletter {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

.popUp_subscription__scroll {
    height: 100%;
    padding: 1.5rem 1rem;
    overflow-y: auto;

    @include breakpoint(medium) {
        padding: 2rem;
    }
}

.karriere-newsletter {
    input[type="checkbox"] {
        &.error {
            border:1px solid red;
        }
    }
    .button {
        margin: .25rem 0 1.25rem 0;
    }
}

.karriere-newsletter__headline {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;
}

.karriere-newsletter__title {
    font-size: 1rem;

    @include breakpoint(large) {
        font-size: 1.125rem;
    }
}



.karriere-newsletter__input {
    width: 100%;
    height: 50px;
    padding: 0 1rem;
    margin: .25rem 0;
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    border: 3px solid #333;
    &.error {
        border-color: #df1051;
    }
}

.newsletter__text-error,
.newsletter__text-info {
    display: block;
    margin: .5rem 0;
    font-size: .75rem;
    line-height: 1.5;
    max-width: 95%;
}

.newsletter__text-error {
    margin: .25rem 0 0;
    color: #df1051;
}