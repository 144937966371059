/*! purgecss start ignore */
.introjs-overlay {
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.introjs-showElement {
  z-index: 9999999 !important;
}

tr.introjs-showElement > td {
  z-index: 9999999 !important;
  position: relative;
}

tr.introjs-showElement > th {
  z-index: 9999999 !important;
  position: relative;
}

.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: #ffffff;
  opacity: 0;
  filter: alpha(opacity=0);
}

.introjs-relativePosition {
  position: relative;
}

.introjs-helperLayer {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.introjs-helperLayer * {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.introjs-helperLayer *:before {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.introjs-helperLayer *:after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.introjs-tooltipReferenceLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.introjs-tooltipReferenceLayer * {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
}

.introjs-helperNumberLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  color: #9e9e9e;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0;

  @include breakpoint(medium down) {
    padding-top: 5px;
  }

  // CAG MK FIX to overwrite static "x of y" text
  // see https://github.com/usablica/intro.js/issues/1485#issuecomment-1025699233
  &:before {
    display: block;
    bottom: 14px;
    font-size: 1rem;
    content: attr(data-step-nums);

    @include breakpoint(medium down) {
      font-size: .8rem;
    }
  }
}

.introjs-arrow {
  border: 5px solid transparent;
  content: "";
  position: absolute;
}

.introjs-arrow.top {
  top: -10px;
  left: 10px;
  border-bottom-color: #000;
}

.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: #000;
}

.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #000;
}

.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: #ffffff;
}

.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: #ffffff;
}

.introjs-arrow.bottom {
  bottom: -10px;
  left: 10px;
  border-top-color: #ffffff;
}

.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: #ffffff;
}

.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: #ffffff;
}

.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: #ffffff;
}

.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: #ffffff;
}

.introjs-tooltip {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #ffffff;
  min-width: 400px;
  max-width: 500px;
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  -webkit-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;

  @include breakpoint(medium down) {
    min-width: 300px;
    max-width: 400px;
  }
}

.introjs-tooltiptext {
  padding: 20px;

  @include breakpoint(medium down) {
    padding: 1rem 1rem 5px;
  }

  p {
    margin-bottom: 0;

    @include breakpoint(medium down) {
      font-size: .8rem;
    }
  }
}

.introjs-tooltip-title {
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  float: left;
  line-height: 32px;
  color: #fff;
}

.introjs-tooltip-header {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #000;

  @include breakpoint(medium down) {
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.introjs-tooltip-header:after {
  content: ".";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.introjs-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  text-align: right;
  white-space: nowrap;
  background-color: #000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.introjs-tooltipbuttons:after {
  content: "";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.introjs-button {
  //-webkit-box-sizing: content-box;
  //box-sizing: content-box;
  //position: relative;
  //overflow: visible;
  //display: inline-block;
  //padding: 0.5rem 1rem;
  //border: 1px solid #bdbdbd;
  //text-decoration: none;
  //text-shadow: 1px 1px 0 #ffffff;
  //font-size: 14px;
  //color: #424242;
  //white-space: nowrap;
  //cursor: pointer;
  //outline: none;
  //background-color: #f4f4f4;
  //border-radius: 0.2em;
  //zoom: 1;
  //*display: inline;
  @extend .button;
  outline: none;

  @include breakpoint(medium down) {
    font-size: .9rem;
    padding: .25rem .5rem;
  }
}

.introjs-button:hover {
  //outline: none;
  //text-decoration: none;
  //border-color: #9e9e9e;
  //background-color: #e0e0e0;
  //color: #212121;
}

.introjs-button:focus {
  //outline: none;
  //text-decoration: none;
  //background-color: #eeeeee;
  //-webkit-box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.5);
  //box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.5);
  //border: 1px solid #616161;
  //color: #212121;
}

.introjs-button:active {
  //outline: none;
  //text-decoration: none;
  //background-color: #e0e0e0;
  //border-color: #9e9e9e;
  //color: #212121;
}

.introjs-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.introjs-skipbutton {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  color: #fff;
  float: right;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  padding: 4px 10px;
}

.introjs-skipbutton:hover, .introjs-skipbutton:focus {
  //color: #212121;
  //outline: none;
  //text-decoration: none;
}

.introjs-prevbutton {
  float: left;
  @extend .button--white;

  &:hover,
  &:focus {
    color: #fff;
  }
}

.introjs-nextbutton {
  float: right;
  //@extend .button--bg-red;
  @extend .button--white;

  &:hover,
  &:focus {
    color: #fff;
  }
}

.introjs-donebutton {
  @extend .button--bg-red;
  border-color: #df1051;
}

.introjs-disabled {
  color: #9e9e9e;
  border-color: #bdbdbd;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none;
}

.introjs-disabled:hover, .introjs-disabled:focus {
  color: #9e9e9e;
  border-color: #bdbdbd;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none;
}

.introjs-hidden {
  display: none;
}

.introjs-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

  @include breakpoint(medium down) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.introjs-bullets ul {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  clear: both;
  margin: 0 auto 0;
  padding: 0;
  display: inline-block;
}

.introjs-bullets ul li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  list-style: none;
  float: left;
  margin: 0 2px;
}

.introjs-bullets ul li a {
  -webkit-transition: width 0.1s ease-in;
  -o-transition: width 0.1s ease-in;
  transition: width 0.1s ease-in;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}

.introjs-bullets ul li a:hover, .introjs-bullets ul li a:focus {
  width: 15px;
  background: #999;
  text-decoration: none;
  outline: none;
}

.introjs-bullets ul li a.active {
  width: 15px;
  background: #999;
}

.introjs-progress {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px;
  border-radius: 4px;
  background-color: #e0e0e0;
}

.introjs-progressbar {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #df1051;
}

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%;
}

.introjs-fixedTooltip {
  position: fixed;
}

.introjs-hint {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  background: transparent;
  width: 20px;
  height: 15px;
  cursor: pointer;
}

.introjs-hint:focus {
  border: 0;
  outline: 0;
}

.introjs-hint:hover > .introjs-hint-pulse {
  background-color: rgba(60, 60, 60, 0.57);
}

.introjs-hidehint {
  display: none;
}

.introjs-fixedhint {
  position: fixed;
}

@-webkit-keyframes introjspulse {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes introjspulse {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.introjs-hint-pulse {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-animation: introjspulse 2s infinite;
  animation: introjspulse 2s infinite;
}

.introjs-hint-no-anim .introjs-hint-pulse {
  -webkit-animation: none;
  animation: none;
}

.introjs-hint-dot {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background: transparent;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  position: absolute;
  top: -18px;
  left: -18px;
  z-index: 1;
  opacity: 0;
}

/*! purgecss end ignore */