$padding-top-desktop: 10vh;
$menu-index: 1000;
$mainmenu-duration: .3s;

.main-menu-slideout__left {
    display: none;
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100vh;
    padding: 2rem;
    background-color: $bg-black;
    color: $white;
    overflow: hidden;
    z-index: $menu-index - 1;

    @media print,
    screen and (min-width: 40em) {
        z-index: $menu-index;
    }

    &.page_loaded {
        display: block;
    } 

    // animate block to left 
    transform: translate(-100%, 0);
    transition: $mainmenu-duration all cubic-bezier(.455, .03, .515, .955) $mainmenu-duration/2;

    .opened-menu & {
        transform: translate(0%, 0);
        transition: $mainmenu-duration all cubic-bezier(.455, .03, .515, .955) 0s;
    }


    &>div {
        display: none;

        @media print,
        screen and (min-width: 40em) {
            display: block;
            width: 30%;
            padding: $padding-top-desktop 3vw 3vw 0;
        }

        @media print,
        screen and (min-width: 64em) {
            width: 25%;
            padding: $padding-top-desktop 4vw 3vw 0;
        }

        
        // animate block content to left on desktop and tablet
        transform: translate(-100%, 0);
        transition: $mainmenu-duration all cubic-bezier(.455, .03, .515, .955) 0s;

        .opened-menu & {
            transform: translate(0%, 0);
            transition: .7s all cubic-bezier(.455, .03, .515, .955) ;
        }
    }
}

.main-menu-slideout__right {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    padding: $padding-top-desktop 2vw 0;
    background-color: $white;
    transition: $mainmenu-duration all cubic-bezier(.455, .03, .515, .955);
    z-index: $menu-index - 1;

    @include breakpoint(medium) {
        width: 70%;
        padding: $padding-top-desktop 3vw 3vw 3vw;
        z-index: $menu-index;
    }

    @include breakpoint(large) {
        width: 75%;
    }

    &.page_loaded {
        display: block;
    }

    // animate block to right 
    transform: translate(100%, 0);
    .opened-menu & {
        transform: translate(0%, 0);
    }

    a {
        hyphens: auto;
        word-break: break-all;
    }
}

// main content to scroll on mobile
.main-menu-slideout__scroll {
    height: 100%;
    padding: 0 3vw;
    overflow-y: auto;

    @include breakpoint(large) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
    }
}

// content wiht headline and button on mobile
.main-menu-slideout__block {
    width: 100%;
    height: auto;
    padding: 2rem 4vw;
    background-color: $bg-black;
    color: $white;

    @include breakpoint(medium) {
        display: none;
    }
}

// headline of slideout
.slideout__headline {
    margin-bottom: 4vw;
    font-size: 2.25rem;
    line-height: 1;
    font-weight: $global-weight-light;

    @include breakpoint(large) {
        font-size: 3.125rem;
    }
}

// bottom menu in navigation
.main-menu__bottom {
    padding: 1rem;
    margin: 1rem 0;
    background-color: #f3f5f7;
    transition: $transition;

    @include breakpoint(medium) {
        padding: 2vw;
        margin: 1rem 0 0;
    }

    // animate block
    opacity: 0;

    .opened-menu & {
        opacity: 1;
        transition-delay: 1.5s;
    }

    h4 {
        font-size: 0.875rem;
        margin: 0 0 .5rem 0;
        text-transform: uppercase;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem;
    }

    li {
        padding: .5rem 1rem;
        font-size: 0.75rem;

        @include breakpoint(large) {
            padding: 0 1rem;
        }
    }

    a {
        color: $black;
        padding: 1rem 0 .125rem;
        text-decoration: none;
    }

}

// main menu list
.mainmenu__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include breakpoint(medium) {
        flex-direction: row;
        flex-wrap: wrap;
    }
    @include breakpoint(large) {
        // grid template
        display: grid;
        grid-auto-columns: 1fr;
        grid-column-gap: 1vw;
        grid-row-gap: 0vw;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: minmax(auto, auto) minmax(auto, auto) minmax(auto, auto) minmax(auto, auto) minmax(auto, auto) minmax(auto, auto) minmax(auto, auto) minmax(auto, auto) minmax(auto, auto);
    }

    &>li {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
        padding: .5rem 0 1rem;
        border-style: solid;
        border-width: 5px;
        border-color: transparent transparent #f3f5f7 transparent;

        // animate menus items
        transition: .2s all cubic-bezier(.455, .03, .515, .955) 0s;

        @include breakpoint(medium) {
            flex: 0 0 calc(50% - 10px);
            max-width: calc(50% - 10px);
        }

        @include breakpoint(large) {
            padding: 1rem 0;
            max-width: 90%;
            
            /*Stellenmarkt*/
            &:nth-child(1) {
                grid-column-end: 3;
                grid-column-start: 1;
                grid-row-end: 3;
                grid-row-start: 1;
                border-color: #f3f5f7 transparent #f3f5f7 transparent;
            }
            /*Jura-Studium*/
            &:nth-child(2) {
                grid-column-end: 5;
                grid-column-start: 3;
                grid-row-end: 3;
                grid-row-start: 1;
                border-color: #f3f5f7 transparent #f3f5f7 transparent;
            }
            /*Jura-Referendariat*/
            &:nth-child(3) {
                grid-column-end: 7;
                grid-column-start: 5;
                grid-row-end: 3;
                grid-row-start: 1;
                border-color: #f3f5f7 transparent #f3f5f7 transparent;
            }
            /*Beruf*/
            &:nth-child(4) {
                grid-row-start: 3;
                grid-row-end: 5;
                grid-column-start: 1;
                grid-column-end: 3;
            }
            /*Arbeitgeber*/
            &:nth-child(5) {
                grid-column-end: 5;
                grid-column-start: 3;
                grid-row-end: 5;
                grid-row-start: 3;
            }
            /*Geld*/
            &:nth-child(6) {
                grid-column-end: 7;
                grid-column-start: 5;
                grid-row-end: 5;
                grid-row-start: 3;
            }
            /*Karrieremessen*/
            &:nth-child(7) {
                grid-column-end: 3;
                grid-column-start: 1;
                grid-row-end: 5;
                grid-row-start: 5;
                border-color: transparent;
            }
            /*Podcast*/
            &:nth-child(8) {
                grid-column-end: 5;
                grid-column-start: 3;
                grid-row-end: 5;
                grid-row-start: 5;
                border-color: transparent;
            }
            /*Für Arbeitgeber*/
            &:nth-child(9) {
                grid-column-end: 7;
                grid-column-start: 5;
                grid-row-end: 7;
                grid-row-start: 5;
                border-color: transparent;
            }
        }

        @include breakpoint(xxxlarge) {
            max-width: 70%;
        }
        
        // animate items 
        opacity: 0;
        transform: translate(0, 10px);

        .opened-menu & {
            opacity: 1;
            transform: translate(0, 0px);

            // add small delay
            @for $i from 0 through 10 {
                &:nth-child(#{$i}) {
                    transition-delay: .2s + 0.05s *$i;
                }
            }
        }

        @include breakpoint(small only) {
            > .mainmenu__sublist {
                max-height: 0;
                overflow: hidden;
                transition: .2s all cubic-bezier(.455, .03, .515, .955) 0s;
            }
        }

        .open-toggle {
            display: inline-block;
            vertical-align: middle;
            padding: 0 1rem;
        }

        &.open {
            .open-toggle .icon {
                transform: rotate(180deg);
            }

            > .mainmenu__sublist {
                max-height: 500px;
            }
        }
    }

    a {
        display: inline-block;
        position: relative;
        padding-top: 10px;
        padding-bottom: 5px;
        color: $black;
        font-size: 1.125rem;
        line-height: 1.2;
        font-weight: 600;
        text-transform: uppercase;
    }

}

.mainmenu__sublist-1 {
    a {
        color: $black;
        font-size: 1.125rem;
        font-weight: 400;
        text-transform: inherit;
        @include breakpoint(medium)  {
            font-size: 1rem;
            word-break: break-word;
        }
    }
}

.mainmenu__sublist-2,
.mainmenu__sublist-3,
.mainmenu__sublist-4 {
    display: none;
}