$header-desktop-padding: 1.5vw;
$header-mobile-padding: 5vw;
$header-height: 8vh;
$header-height-scroll: 6vh;
$header-index: 1010;

.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 0 0 $header-mobile-padding;
  height: $header-height;
  background-color: $black;
  transition: 0.2s all cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: height;
  z-index: $header-index;

  &.scrolling {
    height: $header-height-scroll;
  }

  &.menu-open {
    background-color: transparent;
  }

  @include breakpoint(large) {
    padding: 0 0 0 $header-desktop-padding;
  }

  & > div:not(.main-menu-slideout) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}

.page-header-back-to-lto {
  position: fixed;
  //top: $header-height;
  bottom: 0;
  left: 0;
  background-color: #df1051;
  padding: .5rem 1.5rem .5rem .5rem;
  color: #fff;
  font-weight: 500;
  height: fit-content!important;
  cursor: pointer;
  border-top-right-radius: 8px;
  z-index: 999;

  @include breakpoint(medium) {
    padding: 1rem 2.5rem 1rem 1rem;
  }

  &:hover {
    transition: filter .2s ease-in-out;
    filter: brightness(0.9);
    text-decoration: none!important;
    color: #fff;
  }

  .scrolling & {
    top: $header-height-scroll;
  }
}

.page-header-back-to-lto__close {
  position: absolute;
  top: .5rem;
  right: .5rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.page-header-back-to-lto__link {
  display: flex;
  align-items: center;
  color: #FFF;
  text-decoration: none;
}

.page-header__logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 220px;
}

.page-header__logo {
  position: relative;
  width: 110px;
  height: auto;
  will-change: width;
  transition: width 0.2s ease-in-out;
  z-index: $header-index + 1;

  @include breakpoint(medium) {
    width: 150px;

    .scrolling & {
      width: 110px;
    }
  }
}

//.page-header__logo-lto-karriere {
//  width: 325px!important;
//  height: auto;
//}

// logos
.page-header__logo--black,
.page-header__logo--white {
  width: 100%;
  height: 30px;
}

.page-header__logo--black {
  .menu-close & {
    display: none;
  }

  @include breakpoint(medium) {
    display: none;
  }
}

.page-header__logo--white {
  .menu-open & {
    display: none;

    @include breakpoint(medium) {
      display: block;
    }
  }
}

// top menu in the middle of header
.page-header__menutop {
    display: none;

    @include breakpoint(75em) {
        display: block;
    }

  &.search {
    display: none;
  }

  .menu-open & {
    opacity: 0;
    display: none;
  }

    // First level
    > ul {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style: none;
        opacity: 1;

        // all list items
        > li {
            margin: 0 .5rem;

            // ul level 2
            > ul {
                display: none;
                margin: 0;
                background-color: #0a0a0a;
                list-style: none;
                padding: .5rem 1rem;
                border-left: 5px solid #df1051 ;

                li {
                    margin: 0;
                    a {
                        display: inline-block;
                        width: 100%;
                        padding: .5rem .5rem;
                        text-transform: inherit;

                        &:before {
                            display: none;
                        }
                    }
                }
            }

            &:hover {
                > a {
                    @extend .hover-effect-border:hover;
                }

                > ul {
                    position: absolute;
                    display: block;
                }
            }
        }

        a {
            font-size: .75rem;
            font-weight: 500;
            color: $white;
            text-transform: uppercase;
            
            @include breakpoint(78em) {
              font-size: .875rem;
            }
            @include breakpoint(85em) {
              font-size: 1rem;
            }

            &:hover {
                color: $color-red;
            }
        }

        .active {
            > a {
                color: $color-red;
            }
        }
    }
}

.page-header__login {
  position: relative;
  font-size: 0.85rem;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }

  @include breakpoint(medium) {
    padding-left: 0;
  }

  .overlay {
    position: fixed;
    top: $header-height;
    z-index: 0;
    transform: translate(-100%, 0);

    .scrolling & {
      top: $header-height-scroll;
    }
  }

  &.open-login {
    .overlay {
      transform: translate(0%, 0);
    }
  }
}

.page-header__login-content {
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 20%;
  width: 20rem;
  background-color: $white;
  color: $black;
  padding: 1rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 30%);
  opacity: 0;
  visibility: collapse;
  transform: translate(-50%, 110%);
  word-break: break-word;

  transition: $transition;
  z-index: 10;

  @include breakpoint(medium) {
    top: 100%;
    bottom: auto;
    left: 50%;
    width: 15rem;
    transform: translate(-50%, 10px);
  }

  .open-login & {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 100%);

    @include breakpoint(medium) {
      transform: translate(-50%, 0px);
    }
  }

  p {
    margin: 0;
  }

  li {
    margin: 1rem 0;
    line-height: 1;
    list-style: none;

    a {
      display: flex;
      align-items: center;
      padding: 0;
      color: $black;
      font-size: 0.875rem;
      &:hover {
          color: $color-red;
      }
    }

    .icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.page-header__login-image {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto 1rem;
  overflow: hidden;

  .badge {
    position: absolute;
    top: 0;
    left: 5.5rem;
    @include breakpoint(medium) {
      left: 3rem;
    }
  }
}

//  login and burger
.page-header__menumeta {
  position: relative;
  display: flex;
  height: 100%;
  z-index: $header-index + 1;

  .item {
    display: flex;
    align-items: center;
    height: 100%;

    padding: 0 7.5vw;
    background-color: $color-violet;
    transition: $transition;

    @include breakpoint(medium) {
      padding: 0 $header-desktop-padding;
    }

    .menu-close & {
      color: $white;
    }

    .menu-open & {
      color: $black;
    }

    &:last-child {
      .menu-close & {
        background-color: $color-red;
      }
    }

        &:hover {
            background-color: $color-violet;
            color: $white;
            text-decoration: none;
            .page-header__burger-bottom,
            .page-header__burger-middle,
            .page-header__burger-top {
                background-color: $white;   
            }
            &:last-child {
                .menu-close & {
                    background-color: $color-violet;
                }
            }
        }

    &.page-header__search {
      padding: 0;
      background-color: transparent;

      &.open {
        color: $color-red;
      }
    }
  }
}

.page-header__search {
  margin: 0 2rem 0 0;

  @include breakpoint(small only) {
    margin: 0;
  }

  .opened-menu & {
    display: none;
  }

  button {
    height: 100%;
    padding: 0 0.5rem 0 0;
    margin: 0;
    color: inherit;
    outline: none;
    cursor: pointer;
    transition: $transition;
    color: #fff;

    &:hover {
      color: $color-red;
    }
  }
}

.page-header__form {
  display: none;
  position: fixed;
  top: 8vh;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  padding: 0.75rem 0.5rem;
  margin: 0 1rem 0 0;
  background-color: $black;
  animation: openSearch 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1
    forwards;
  transition: $transition;

  .scrolling & {
    top: 6vh;
  }

  @include breakpoint(medium) {
    position: static;
    width: 300px;
    height: 40px;
    padding: 0;
  }

  @include breakpoint(large) {
    width: 400px;
  }

  &.open {
    display: flex;
  }

  input[type="text"] {
    height: 100%;
    padding: 0.5rem 0.75rem;
    margin: 0;
    font-size: 0.875rem;
    background: transparent;
    color: $white;
    border-style: solid none solid solid;
    border-width: 3px;
    border-color: $white;

    &::placeholder {
      color: $white;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  button {
    display: flex;
    padding: 0.85rem;
    width: auto;
    color: $white;
    background-color: $color-red;
    font-size: 0.875rem;

    &:hover {
      color: $white;
      background-color: $color-violet;
    }
  }

  svg {
    @include breakpoint(large) {
      margin: 0 0.875rem 0 0;
    }
  }

  span {
    display: none;

    @include breakpoint(large) {
      display: inline;
    }
  }
}

.page-header__login-text {
  display: none;

  @include breakpoint(medium) {
    display: inline-block;
    margin-left: 1rem;
    text-transform: uppercase;
  }
}

.page-header__menu {
  display: inline-block;
  text-transform: uppercase;
}

.show {
  background-color: $color-violet;
}

// burger button
$burger-border-delay: 0.2s;

.page-header__burger {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 20px;
    transition: height $global-transition-style $burger-border-delay;

    .show & {
        height: 13px;
    }

  @include breakpoint(medium) {
    margin: 0 1rem 0 0;
  }

    .page-header__burger-bottom,
    .page-header__burger-middle,
    .page-header__burger-top {
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: $white;
        transform: translate(0px, 0px) rotateZ(0deg);
        transform-style: preserve-3d;
        transition: transform $global-transition-style 0s, top $global-transition-style $burger-border-delay, bottom $global-transition-style $burger-border-delay, background-color $global-transition-style 0s;
        
        .menu-close & {
            background-color: $white;
        }

    .menu-open & {
      background-color: $black;
    }
  }

  .page-header__burger-top {
    top: 0;

    .show & {
      top: 5px;
      transform: rotateZ(45deg);
      transition: transform $global-transition-style $burger-border-delay,
        top $global-transition-style 0s, bottom $global-transition-style 0s;
    }
  }

  .page-header__burger-bottom {
    bottom: 0;

        .show & {
            bottom: 6px;
            transform: rotateZ(-45deg);
            transition: transform $global-transition-style $burger-border-delay, top $global-transition-style 0s, bottom $global-transition-style 0s;
        }
    }
    .page-header__burger-middle {
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
        transition: opacity $global-transition-style $burger-border-delay;

        .show & {
            opacity: 0;

        }
    }
}

.page-header__burger-text {
  display: none;

  @include breakpoint(medium) {
    display: inline-block;
    text-transform: uppercase;
  }
}

@keyframes openSearch {
  0% {
    opacity: 0;
    transform: translate(20px, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0);
  }
}
