
.code-copy {
    
    @include breakpoint(large) {
        max-width: 80%;
    }
    
    .code {
        display: block;
        padding: 1rem 5rem 5rem 1rem;
        margin: 1rem 0;
        
    }

    .copy {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

