.articledetails__text {
    h2, .h2 {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        font-size: 2rem;

        &:first-child {
            margin-top: 0rem;
        }
    }
}