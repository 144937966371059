.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 5rem;
  padding: 2rem 20px;
  z-index: 999;
  color: #ffffff;
  box-shadow: 0px -2px 13px 1px rgba(0, 0, 0,0.25);
  transition: right cubic-bezier(.455, .03, .515, .955) .3s;

  @media print, screen and (min-width: 40em) {
    flex-direction: row;
    left: 5rem;
    right: 5rem;
  }

  > *:not(:last-child):not(.close) {
    margin-bottom: 1rem;

    @media print, screen and (min-width: 40em) {
      margin-right: 1rem;
    }
  }
}

//.spaceForBottomActions {
//  right: 5rem;
//  transition: right cubic-bezier(.455, .03, .515, .955) .15s;
//}

.icon {
  @media print, screen and (min-width: 40em) {
    flex: 10%;
  }
}

.text {
  font-size: 1rem;

  @media print, screen and (min-width: 40em) {
    flex: 60%;
    font-size: 1.125rem;
  }
}

.buttonContainer {
  text-align: center;

  @media print, screen and (min-width: 40em) {
    flex: 20%;
  }

  @media print, screen and (min-width: 40em) and (max-width: 64em) {
    flex: 33%;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  top: .5rem;
  right: .75rem;
}