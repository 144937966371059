//
// This file is used to include / exclude modules and styles of Foundation v6.4.x
//

// some very basic settings, eg. normalize.css, etc.
@include foundation-global-styles;

// choose a grid variant, if desired
@include foundation-xy-grid-classes; // basic foundation
//@include foundation-grid; // legacy
//@include foundation-flex-grid; // legacy (basic foundation)

// helper classes - might be useful
@include foundation-visibility-classes;
//@include foundation-float-classes;
@include foundation-flex-classes;
//@include foundation-prototype-classes;

// some typical, very basic components
@include foundation-typography;
@include foundation-text-alignment;
//@include foundation-forms;
//@include foundation-button;
@include foundation-button-group;

// all those nice Foundation components...
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;

// CAG MK 2024-04-25 - LR-2194: fix with 33.33333% columns in firefox
@-moz-document url-prefix() {
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      margin-left: 0.624rem;
    }
  }
}