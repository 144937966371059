//
// General styles for html elements, basic typography helpers
//
/*! purgecss start ignore */

.text-small {
  font-size: 0.875rem;
}

.text-default {
  font-size: 1rem;
}

.text-medium {
  font-size: 1.25rem;
}

.text-large {
  font-size: 1.5rem;
}
.text-size-large {
  font-size: 3rem;
}

.text-size-large-medium {
  font-size: 2rem;
}

.text-size-small {
  font-size: .925rem;
}

.text-light {
  font-weight: 300!important;
}

.text-highlighter {
  background-color: #F5FF00;
  color: $black;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-line-through {
  text-decoration-line: line-through;
}

.text-grey {
  color: #8a8a8a;
}

p {
  font-size: 1.125rem;
}

ol {
  margin-left: 2.25rem;
  @include breakpoint(medium) {
    margin-left: 1.25rem;
  }
}

ul {
  margin-bottom: 0;
}

li {
  font-size: 1.125rem;
}

.text-style--default {
  font-style: normal!important;
}

.headline-with-bottom-divider {
  position: relative;
  margin-bottom: 1rem;

  &:after {
    content: "";
    width: 43px;
    background-color: #000;
    height: 1px;
    bottom: -11px;
    position: absolute;
    left: 0;
  }
}
/*! purgecss end ignore */