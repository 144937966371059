.recharts-responsive-container {
}

.recharts-legend-wrapper {
  @include breakpoint(large) {
    .recharts-jobdetails & {
      bottom: -15px !important;
    }
  }
}
