.add_to_bookmarks {
    @include breakpoint(medium) {
        margin: 0;
    }
}

.add_to_bookmarks__button {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.7rem;
    text-align: center;
    line-height: 1.2;
    cursor: pointer;
    transition: $transition;
    width: 4rem;
    &:hover {
        color: $color-red;
    }
}
.bookmark-list {
    margin: 2rem 0;
}

.bookmark-item {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem 2rem;
    margin: 2rem 0; 
    border: 1px dashed rgba(0, 0, 0, 0.2);  
    @include breakpoint(medium) {
        flex-direction: row;
        justify-content: space-between;
        gap: 0rem;
    }
}

.bookmark-item__right,
.bookmark-item__left {
    display: flex;
}

.bookmark-item__left {
    flex-direction: column;
    align-items: flex-start;
}

.bookmark-item__right {
    gap: 2rem;
    align-items: center;
    justify-content: flex-end;
}

.bookmark-item__title {
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
    
    a {
        color: $black;
    }

    &:hover {
        text-decoration: underline;       
    }
}

.bookmark-item__city {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0 0;
    svg {
        flex: 0 0 1.25rem;
        max-width: 1.25rem;
    }
    span {
        display: inline-block;
        margin-left: 0.5rem;
        flex: 1;
    }
}