//
// style for some mask content
//

.ce--mask_job_searchform_widget {
    height: 100%;
    & > .grid-x {
        height: 100%;
    }    
}

.ce-elements {
    
    & > .ce.ce--mask_jobs_widget,
    & > .ce.ce--mask_employer_contacts {
        padding: 0;
    }

    // because of ce-elements no more padding-top (LR-454)
    .ce--container_50_50 {
        .ce--mask_employer_teaser,
        .ce--mask_job_searchform_widget {
            @include breakpoint(large) {
                margin-top: $general-padding;
            }
        }
    }
}

.ce--mask_employer_teaser,
.ce--mask_jobs_widget {
    @include breakpoint(medium down) {
        padding: 2rem 0 0;
    }
}

.ce--mask_quick_search_widget {
    overflow: hidden;
}