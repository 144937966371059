.ce--layout-bg-color-dark {
	background-color: #1c222e;
	color: $white;
	padding: $general-padding !important;
	@include breakpoint(medium) {
		padding: 2rem !important;
	}
}

.ce--layout-bg-color-white {
	background-color: #fff;
	color: $body-font-color;
	padding: $general-padding !important;
	@include breakpoint(medium) {
		padding: 2rem !important;
	}
}

.ce--layout-bg-highlight-color {
	background-color: var(--highlight-color);
	color: var(--highlight-invert-bw-color);
	padding: $general-padding !important;
	@include breakpoint(medium) {
		padding: 2rem !important;
	}
	a {
		color: $white;

		&:not(.button) {
			text-decoration: underline;
		}
	}
}

.ce--layout-bg-color-gray {
	background-color:#f3f5f7;
	padding: $general-padding !important;
	@include breakpoint(medium) {
		padding: 2rem !important;
	}
}

.ce--layout-column-bg-color-gray {
	.container-column {
		background-color: #f3f5f7;
	}
}

.ce--layout-border-color-gray {
	border: 10px solid #cad0d6;
	padding: $general-padding !important;

	@include breakpoint(medium) {
		padding: 2rem !important;
	}
}

$layout-border-width: 5px;
.ce--layout-top-bottom-border-color-gray,
.ce--layout-top-border-color-gray {
	border-top: $layout-border-width solid #cad0d6;
	padding: $general-padding 0 !important;

	@include breakpoint(medium) {
		padding: 2rem !important;
	}
}

.ce--layout-top-bottom-border-color-gray,
.ce--layout-bottom-border-color-gray {
	border-bottom: $layout-border-width solid #cad0d6;
	padding: $general-padding 0 !important;

	@include breakpoint(medium) {
		padding: 2rem !important;
	}
}

.ce--container {
	.ce {
		&.ce--space-before-default {
			margin-top: 0;
		}
		
		&.ce--space-after-default {
			margin-bottom: 1.25rem;
		}
	}
}