.footer {
    position: relative;
    padding: 2rem 1rem;
    color: $white;
    background-color: $black;
    //z-index: 1000;
    @include breakpoint(large) {
        padding: 5vh 10vh;
        //z-index: 100;
	}
}

.footer__item {
    margin: 2rem 0;
    text-align: center;
    @include breakpoint(large) {
        padding: 0 2rem;
        margin: 0;
	}
}

.footer__headline {
    margin: 1.25rem 0 0 0;
    font-size: 1.5rem;
    line-height: 1.2;
}

.footer__nav {
    margin-top: 2rem;
    justify-items: center;
}

.footer__list {

}

.nav_headline {
    margin: .3125rem 0;
    font-size: 1rem;
    font-weight: $global-weight-bold;
}

.footer__link {
    display: inline-block;
    margin: 0 0 .75rem 0;
    color: $white;
    font-size: .875rem;
    font-weight: $global-weight-light;
    letter-spacing: 0.5px;

    &:hover {
        color: $color-red;
        text-decoration: none;
    }
}

.footer__meta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 0;
}

.footer__copyright {
    margin: 0 0 1.125rem 0;
    font-size: .875rem;
    text-align: center;
    @include breakpoint(medium) {
        font-size: 1rem;
	}
}