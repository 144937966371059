.icon {
    &.left {
        float: left;
        margin-right: 0.5rem;
    }

    &.right {
        float: right;
        margin-left: 0.5rem;
    }

    &.top {
        margin-bottom: 0.5rem;
    }

    &.bottom {
        margin-top: 0.5rem;
    }

    &.align--center {
        vertical-align: sub;
    }
}