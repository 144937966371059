.highlight-color {
  color: $color-red !important;

  .ce--layout-bg-highlight-color & {
    color: var(--highlight-invert-bw-color);
  }

  .ce--layout-bg-color-dark & {
    color: var(--highlight-invert-for-dark-bg-color)!important;
  }

  .ce--layout-border-color-gray &,
  .ce--layout-bg-color-gray & {
    color: var(--highlight-invert-for-bright-bg-color)!important;
  }
}

:root {
  --highlight-color: #{$color-red};
  --highlight-invert-for-bright-bg-color: #{$color-red};
  --highlight-invert-for-dark-bg-color: #{$color-red};
  --highlight-invert-bw-color: #{$white};
}