.wrapper {
    //position: fixed;
    //background: #FFF;
    //width: 5rem;
    //height: 5rem;
    //bottom: 5rem;
    //right: 1rem;
}

.icon {
    transform: rotate(180deg);
    margin: 0!important;
}