.ring
{
  position: relative;
  width:150px;
  height:150px;
  background:#fff;
  border:6px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  box-shadow:0 0 20px rgba(0,0,0,.5);
  margin: 10rem auto;

  @media print, screen and (min-width: 64em) {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
  }
}
.ring:before
{
  box-sizing: initial;
  content:'';
  position:absolute;
  top:-6px;
  left:-6px;
  width:100%;
  height:100%;
  border:6px solid transparent;
  border-top:6px solid #df1051;
  border-right:6px solid #df1051;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}

.span {
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}

.span:before {
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#df1051;
  top:-6px;
  right:-11px;
  box-shadow:0 0 20px #df1051;
}

.logo {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.subText {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
  width: 20rem;
  font-size: 1.25rem;
}

@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}