:root {
    --swiper-navigation-size: 30px;

    @include breakpoint(medium) {
        --swiper-navigation-size: 40px;
    }
}

// postion of button prev and next
.swiper-button-next {
    right: 0;
    @include breakpoint(medium) {
        right: -45px;
    }
}

.swiper-button-prev {
    left: 0;
    @include breakpoint(medium) {
        left: -45px;
    }
}

.swiper-button-next,
.swiper-button-prev {
    top: auto;
    bottom: -2rem;
    padding: 3rem;
    margin: 0;
    @include breakpoint(medium) {
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
        padding: 0;
    }
}

.ce--layout-header-left-dark {

    .swiper-pagination {
        margin-top: 2rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: #FFFFFF;
    }
    .swiper-pagination-bullet {
        background-color: #FFFFFF;
    }
}