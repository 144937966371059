//
// Styles for icons used on the website, they are included as inline svg sprite code and / or external svg sprite file
//

.icon {

    /* Lets the icon inherit the text color. */
    fill: currentColor;
  
    /* Inherit the text’s size too. Also allows sizing
       the icon by changing its font-size. */
    width: 1.25rem;
    height: 1.25rem;
  
    /* Nice visual alignment for icons alongside text.
       (I got a few questions about this and: with most
       fonts and styles, this works better than just
       vertical-align:middle. Try it and see what you
       like best. */
    vertical-align: -.15em;
}

.icon--extra-small {
   width: .45rem;
   height: .45rem;
}

.icon--very-small {
   width: .625rem;
   height: .625rem;
}

.icon--small {
   width: .875rem;
   height: .875rem;
}

.icon--standard {
   width: 1rem;
   height: 1rem;
}

.icon--default {
   width: 1.25rem;
   height: 1.25rem;
}

.icon--base {
   width: 1.5rem;
   height: 1.5rem;
}

.icon--medium {
   width: 2rem;
   height: 2rem;
}

.icon--large {
   width: 3rem;
   height: 3rem;
}

.icon--xlarge {
   width: 4rem;
   height: 4rem;
}

.icon--xxxlarge {
   width: 8rem;
   height: 8rem;
}