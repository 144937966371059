.contact-card,
.dashboard-card {
    height: 100%;
    border: 1px solid $light-gray;
    border-radius: 20px;
    background-color: $white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dashboard-card--auto {
    height: auto;
}

.dashboard-card__icon {
    width: 45px;
    height: 45px; 
    background-color: red;
    border-radius: 50%;
}

.dashboard-card__chart {
    border: 1px solid $light-gray;
    border-radius: 20px;
    background-color: $white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dashboard-card__progress {
    
    div {
        margin: 0;
    }
}

.dashboard-card__profile {
    @include breakpoint(medium) {
        height: 5rem;
    }
}

.dashboard-card__option {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid $light-gray;

    a {
        color: $black;
        &:hover {
            color: $color-red;
        }
    }
}