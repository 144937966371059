//
// global spaces classes for adding styles to elements
//

$spaces: (
  "pd": "padding",
  "mg": "margin",
);
$valid-sides: top, bottom, left, right, global;
$breakpoints-sides: small, medium, large, xlarge;
$gap: 0.25rem;
$min: 0;
$max: 30;

@each $name, $attribute in $spaces {
  @each $side in $valid-sides {
    @for $i from $min through $max {
      .#{$name}-#{$side}-#{$i} {
        @if $side == global {
          #{$attribute}: $gap * $i;
        }
        @else {
          #{$attribute}-#{$side}: $gap * $i!important;
        }
      }
    }
  }
}
@each $name, $attribute in $spaces {
  @each $point in $breakpoints-sides {
    @each $side in $valid-sides {
      @for $i from 0 through 10 {
        .#{$name}-#{$point}-#{$side}-#{$i} {
          @include breakpoint($point) {
            @if $side == global {
              #{$attribute}: $gap * $i;
            }
            @else {
              #{$attribute}-#{$side}: $gap * $i;
            }
          }
        }
      }
    }
  }
}
