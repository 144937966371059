/**
 * CE frame classes
 */

.ce--frame-sticky {
  position: sticky;
  top: 9vh;
  @include breakpoint(large) {
    padding: 1rem 0 0 1rem;
  }
}

/**
 * Set default frame width of elements
 */
.ce--frame-default {

  @extend .max-content;
  .ce--frame-default {
    max-width: 100%;
  }
}

// one column layout - set ce in first level to max width
.page-layout--standard .page-content__content-elements > div > .ce-elements {

  > .ce--frame-default {
    &.ce--text,
    &.ce--textpic,
    &.ce--bullets,
    &.ce--table,
    &.ce--html {
      max-width: 60rem!important;
      margin-left: auto;
      margin-right: auto;
      @include breakpoint(large) {
        padding: 0;
      }
    }
  }

  > .ce--frame-text-width {
    max-width: 60rem!important;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(large) {
      padding: 0;
    }
  }
}

.force-text-width {
  max-width: 60rem!important;
}

.ce--frame-fluid {

  &.ce--container {
    @extend .fluid;
    max-width: inherit;
    width: inherit;
  }
}

// LR-451 ⁃ Podcast-Player zentriert ausrichten
.ce--html {
  audio {
    width: 100%;
  }
}